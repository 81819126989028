.title {
  font-size: 1.8vw;
}
.subTitle {
  font-size: 1vw;
}
.subTitle2 {
  font-size: 0.8vw;
}
.bodyText {
  font-size: 0.7vw;
}
.bodyText2 {
  font-size: 12px;
}
.about-container {
  width: 80vw;
  height: fit-content;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* SLIDER */
.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #d3d3d3;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background: #04aa6d;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04aa6d;
  cursor: pointer;
}

/* Begin Media Queries*/
@media screen and (max-width: 1280px) {
  .about-container {
    width: 90vw;
    height: fit-content;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .title {
    font-size: 2vw;
  }
  .subTitle {
    font-size: 1.2vw;
  }
  .subTitle2 {
    font-size: 1vw;
  }
  .bodyText {
    font-size: 0.9vw;
  }
  .bodyText2 {
    font-size: 12px;
  }

  /* SLIDER */
  .slidecontainer {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .title {
    font-size: 2.8vw;
  }
  .subTitle {
    font-size: 2.5vw;
  }
  .subTitle2 {
    font-size: 2.2vw;
  }
  .bodyText {
    font-size: 2.5vw;
  }
}
@media screen and (max-width: 825px) {
  .bodyText2 {
    font-size: 0.6em;
  }

  /* SLIDER */
  .slidecontainer {
    width: 100%;
  }

  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    background: #d3d3d3;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
  }

  .slider:hover {
    opacity: 1;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #04aa6d;
    cursor: pointer;
  }

  .slider::-moz-range-thumb {
    width: 10px;
    height: 10px;
    background: #04aa6d;
    cursor: pointer;
  }
}
