@tailwind base;
@tailwind components;
@tailwind utilities;

.mapboxgl-popup{
  z-index:1000;
}

@layer components{
    .w-88{
      width: 22rem;
    }
    .w-108{
      width:32rem;
    }
    .w-120{
      width:40rem;
    }
    .h-108{
      height:32rem;
    }
  }
